import styled from '@emotion/styled';
import { SurfaceCard, Text } from '@imus/services-ui';
import { clickableStyle } from 'imdui/src/components/button/Clickable';

export const BioCard = styled(SurfaceCard)`
  position: relative;
  flex-direction: column;
  ${Text.ContentM},
  ${Text.BodyS} {
    word-break: break-all;
  }
`;

export const BioLink = styled(SurfaceCard)`
  border-radius: var(--header-gap, 16px);
  border-radius: var(--space-S, 16px);
  align-items: center;
  ${clickableStyle}

  svg {
    --on-surface: var(--accent);
  }

  ${Text.ContentM},
  ${Text.BodyS} {
    word-break: break-all;
  }
`;
